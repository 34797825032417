import React from 'react'
import { FaTwitter } from "react-icons/fa"
import { FaTelegramPlane } from "react-icons/fa";
const Navbar = () => {
  return (
    <div className='navbar flex justify-between items-center px-3 py-2'>
      <div>
        <p className='text-xl font-bold'>BRABBIT</p>
      </div>
      <div className='icons flex gap-5 text-2xl'>
        <a href='https://x.com/brabbitonsol' target='_blank' rel="noreferrer"><FaTwitter className='icon' /></a>
        <a href='https://t.me/brabbitonsolana' target='_blank' rel="noreferrer"><FaTelegramPlane className='icon' /></a>
      </div>
    </div>
  )
}

export default Navbar
