import React from 'react'
import logo from "../medias/logo_trans.png"

const Top = () => {
  return (
    <div className='flex items-center justify-center gap-3'>
      <img className='w-[75px] md:w-[125px]' src={logo} alt='logo' />
      <p className='text-3xl md:text 6xl font-bold'>$BRAB</p>
    </div>
  )
}

export default Top
