import React from 'react'
import gif from "../medias/gif.MP4"
const Section = () => {
  return (
    <div className='section flex items-center justify-center flex-col'>
      <video autoPlay muted loop>
        <source src={gif} type='video/mp4' />
      </video>
      <a href='https://x.com/intent/tweet?text=We all love $BRAB The Bouncing Rabbit @brabbitonsol https://t.me/brabbitonsolana' target='_blank' rel="noreferrer">Bounce With $Brab</a>
    </div>
  )
}

export default Section
