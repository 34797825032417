import './App.css';
import Navbar from './components/navbar';
import Section from './components/section';
import Top from './components/top';

// Yeah this is going to $100m

function App() {
  return (
    <>
      <Navbar />
      <Top />
      <Section />
    </>
  );
}

export default App;
